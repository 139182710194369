import { getFriendlyName } from '../api/PoolxApi';
import { Round } from '../types/poolx';

export function exportRows(rows: string[], round: Round) {
    const textContent = round.id + "\n" + rows.join('\n');
    const fileName = getFriendlyName(round.name) + "_" + round.id + "_" + round.openTo + ".txt";

    // Create a blob with the text content
    const blob = new Blob([textContent], { type: "text/plain" });

    // Create an anchor element and simulate a click to download the file
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;

    // Simulate the click and clean up
    link.click();
    URL.revokeObjectURL(link.href);
}