import React, { useEffect } from "react";
import { Round } from "./types/poolx";
import { Container, FormControl, FormControlLabel, FormLabel, Grid2, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, SelectChangeEvent } from "@mui/material";
import { getFriendlyName, getPoolXRounds } from "./api/PoolxApi";
import SystemCoupon from "./SystemCoupon";
import { format } from "date-fns";
import FreeCoupon from "./FreeCoupon";

export default function CouponView() {

    const [poolXRounds, setPoolXRounds] = React.useState<Round[]>([]);
    const [selectedRoundId, setSelectedRoundId] = React.useState<number>(0);
    const [selectedType, setSelectedType] = React.useState<string>("typ_R");

    useEffect(() => {
        if (poolXRounds.length === 0) {
            getPoolXRounds(setPoolXRounds);
        }

    }, [poolXRounds]);

    const handleChange = (event: SelectChangeEvent<number>) => {
        setSelectedRoundId(event.target.value as number)
    }

    const handleChangeType = (event: SelectChangeEvent<unknown>) => {
        setSelectedType(event.target.value as string)
    }

    let selectedRound = poolXRounds.find(r => r.id === selectedRoundId);

    return (
        <Container maxWidth='xl' sx={{ mt: 4, mb: 4 }}>
            <Grid2 container spacing={3} id="coupons" direction="column">
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', marginRight: '20px', marginTop: '20px' }}>
                    <FormControl>
                        <InputLabel key="select-label" id="select-label">Välj omgång</InputLabel>
                        <Select
                            labelId="select-label"
                            aria-labelledby="select-label"
                            id="select"
                            label="Välj omgång"
                            value={selectedRoundId}
                            onChange={(event) => handleChange(event)}
                        >

                            <MenuItem key={"round" + 0} value={0}>Välj</MenuItem>
                            {
                                poolXRounds.map((round, index) =>
                                    <MenuItem key={"round" + index} value={round.id}>{getFriendlyName(round.name) + " - " + format(new Date(round.openTo), 'yyyy-MM-dd HH:mm')}</MenuItem>
                                )

                            }
                        </Select>
                    </FormControl>

                    <FormControl sx={{ marginTop: "20px" }}>
                        <FormLabel id="select-type">Välj reduceringstyp</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="select-type"
                            name="row-radio-buttons-group"
                            value={selectedType}
                            onChange={(event) => handleChangeType(event)}
                        >
                            <FormControlLabel value="typ_R" control={<Radio />} label="R-System" />
                            <FormControlLabel value="typ_fri" control={<Radio />} label="Fri reducering" />

                        </RadioGroup>
                       
                    </FormControl>

                </Paper>

                {selectedRound && selectedType === "typ_R" &&
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', marginRight: '20px', marginTop: '20px' }}>
                        <SystemCoupon round={selectedRound} />
                    </Paper>
                }
                {selectedRound && selectedType === "typ_fri" &&
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', marginRight: '20px', marginTop: '20px' }}>
                        <FreeCoupon round={selectedRound} />
                    </Paper>
                }

            </Grid2>
        </Container>
    );

}