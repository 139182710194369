import { FixturePercentages } from "./poolx";

export interface UserCoupon {
    couponFixtures: CouponFixture[]
    roundId: number;
    system: string;
}
export interface CouponFixture {
    gameIndex: number;
    "1": boolean;
    "X": boolean;
    "2": boolean;
    percentages: FixturePercentages;
    isMathematical: boolean;
}

export class CouponInfo{
    public numRows: number;
    public numHalfCover: number;
    public numWholeCover: number;

    constructor(numRows: number, numHalfCover: number, numWholeCover: number) {
        this.numRows = numRows;
        this.numHalfCover = numHalfCover;
        this.numWholeCover = numWholeCover;         
    }
}

export class SignNode {
    // Declare properties
    public level: number;
    private sign: string;
    private parents: string;
    public row: string = "";
    public children: SignNode[] = [];
    public probability: number;

    // Constructor with two parameters
    constructor(level: number, sign: string, parents: string, probability:number) {
        this.level = level;
        this.sign = sign;
        this.parents = parents;
        this.probability = probability;
        if (level === 1)
            this.row = sign + ",";
        else if (level > 1)
            this.row = parents + sign + ",";
    }
}