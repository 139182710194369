import { Grid2, SxProps, ToggleButton, Typography, Theme, Button, Box, Slider } from '@mui/material';
import { Round } from './types/poolx';
import { CouponInfo, SignNode, UserCoupon } from './types/usercoupon';
import React, { useEffect } from 'react';
import { exportRows } from './common/FileUtils2';

const FreeCoupon = ({ round }: { round: Round }) => {

    let [coupon, setCoupon] = React.useState<UserCoupon>();
    let [colorCoupon, setColorCoupon] = React.useState<UserCoupon>();
    let [valid, setValid] = React.useState(false);
    let [couponInfo, setCouponInfo] = React.useState(new CouponInfo(0, 0, 0));
    let [update, setUpdate] = React.useState(0);
    let [reducedRows, setReducedRows] = React.useState<string[]>([]);
    const [winningsRange, setWinningsRange] = React.useState<number[]>([0, 100000]);
    const [colorRange, setColorRange] = React.useState<number[]>([0, 5]);
    const [reducedPercentages, setReducedPercentages] = React.useState<number[][]>([]);

    useEffect(() => {
        if (round && (coupon === undefined || coupon.roundId !== round.id)) {
            let newCoupon: UserCoupon = { roundId: round.id, couponFixtures: [], system: "" }
            let newColorCoupon: UserCoupon = { roundId: round.id, couponFixtures: [], system: "" }

            if (round?.fixtures) {
                for (let index = 0; index < round?.fixtures.length; index++) {
                    newCoupon.couponFixtures.push({
                        gameIndex: index, "1": false, "X": false, "2": false,
                        isMathematical: false, percentages: round.fixtures[index].stakePercentages
                    });
                    newColorCoupon.couponFixtures.push({
                        gameIndex: index, "1": false, "X": false, "2": false,
                        isMathematical: false, percentages: round.fixtures[index].stakePercentages
                    });
                }
            }
            setCoupon(newCoupon);
            setColorCoupon(newColorCoupon);
        }
    }, [coupon, round]);


    if (round === undefined || coupon === undefined || colorCoupon === undefined) {
        return (<></>);
    }

    if (round.id !== coupon.roundId) {
        return (<></>);
    }

    let button: SxProps<Theme> = {
        padding: "0px 5px",
        textAlign: "center",
        height: "40px",
        minWidth: "40px",
        maxWidth: "40px",
        marginRight: "10px",
    }

    let buttonBlock: SxProps<Theme> = {
        padding: "0px 5px",
        textAlign: "center",
        height: "40px",
        minWidth: "40px",
        maxWidth: "40px",
        marginRight: "10px",
        backgroundColor: "#90EEBF",
        "&:hover": {
            backgroundColor: "#90EEBF"
        },
        "&.Mui-selected": {            
            backgroundColor: '#19A519',            
        },
        "&.Mui-selected:hover": {            
            backgroundColor: '#19A519'
        }

    }
    let signText: SxProps<Theme> = {
        fontSize: "13px",
        lineHeight: "18px",
    }

    let teams: SxProps<Theme> = {
        fontSize: "13px",
        lineHeight: "19px",
        fontWeight: "500",
        minWidth: "40px"
    }

    let gameNr: SxProps<Theme> = {
        width: "20px",
        marginRight: "5px"
    }

    const styleRow: SxProps<Theme> = {
        padding: "10px",
        borderBottom: "1px solid"
    };

    const handleRangeChange = (event: Event, newValue: number | number[]) => {
        setWinningsRange(newValue as number[]);
    };

    const handleColorRangeChange = (event: Event, newValue: number | number[]) => {
        setColorRange(newValue as number[]);
    };

    function valuetext(value: number) {
        return `${value} kr`;
    }

    function getWinnings(probability: number, round: Round) {
        let numWinners = round.jackpotUnits * 1.25 * probability + 1;

        if (round.fixtures.length > 8) {
            numWinners = round.jackpotUnits * probability / 0.6 + 1;
        }

        let winnings = round.jackpotUnits / numWinners;

        return winnings;
    }

    function getBlock(node: SignNode) {

        if (colorCoupon?.couponFixtures === undefined)
            return 0;

        let numBlock = 0;

        for (let index = 0; index < colorCoupon?.couponFixtures?.length; index++) {
            const game = colorCoupon?.couponFixtures[index];

            let sign = node.row.split(',')[index];

            if (sign === "1" && game["1"]) {
                numBlock++;
            }

            if (sign === "X" && game["X"]) {
                numBlock++;
            }

            if (sign === "2" && game["2"]) {
                numBlock++;
            }

        }

        return numBlock;

    }

    function onButtonChange(gameIndex: number, selection: string): void {

        setCoupon((prev) => {

            let newCoupon = prev as UserCoupon;

            switch (selection) {
                case "1":
                    newCoupon.couponFixtures[gameIndex]["1"] = !(prev as UserCoupon).couponFixtures[gameIndex]["1"];
                    break;
                case "X":
                    newCoupon.couponFixtures[gameIndex]["X"] = !(prev as UserCoupon).couponFixtures[gameIndex]["X"];
                    break;
                case "2":
                    newCoupon.couponFixtures[gameIndex]["2"] = !(prev as UserCoupon).couponFixtures[gameIndex]["2"];
                    break;
            }

            return newCoupon;

        });
        setValid(validateCoupon(coupon as UserCoupon));
        setReducedRows([]);
        setUpdate((old) => old + 1);
    }

    function onButtonChangeColor(gameIndex: number, selection: string): void {

        setColorCoupon((prev) => {

            let newCoupon = prev as UserCoupon;

            switch (selection) {
                case "1":
                    newCoupon.couponFixtures[gameIndex]["1"] = !(prev as UserCoupon).couponFixtures[gameIndex]["1"];
                    break;
                case "X":
                    newCoupon.couponFixtures[gameIndex]["X"] = !(prev as UserCoupon).couponFixtures[gameIndex]["X"];
                    break;
                case "2":
                    newCoupon.couponFixtures[gameIndex]["2"] = !(prev as UserCoupon).couponFixtures[gameIndex]["2"];
                    break;
            }

            return newCoupon;

        });
        setUpdate((old) => old + 1);
    }


    function addSign(signNode: SignNode, coupon: UserCoupon) {
        var nextLevel = signNode.level + 1;

        if (signNode.level < coupon.couponFixtures.length) {
            let signs: string[] = [];
            let probs: number[] = [];
            let couponFixture = coupon.couponFixtures[signNode.level];

            if (couponFixture["1"]) {
                signs.push("1");
                probs.push(couponFixture.percentages["1"]);
            }
            if (couponFixture["X"]) {
                signs.push("X");
                probs.push(couponFixture.percentages["X"]);
            }
            if (couponFixture["2"]) {
                signs.push("2");
                probs.push(couponFixture.percentages["2"]);
            }

            for (let i = 0; i < signs.length; i++) {
                let sign = signs[i];
                let probability = probs[i] * signNode.probability;

                var node = new SignNode(nextLevel, sign, signNode.row, probability);
                signNode.children.push(node);
                addSign(node, coupon);
            }
        }

        return;
    }

    function getRows(node: SignNode, rows: string[]) {
        for (let i = 0; i < node.children.length; i++) {
            let child = node.children[i];
            if (child.children.length === 0) {
                let rawRow = child.row.substring(0, child.row.length - 1);
                let winnings = getWinnings(child.probability, round);
                let numBlock1 = getBlock(child);

                if (winnings > winningsRange[0] && winnings < winningsRange[1] &&
                    numBlock1 >= colorRange[0] && numBlock1 <= colorRange[1]
                ) {
                    rows.push("E," + rawRow);
                }
            }
            getRows(child, rows);
        }
        return rows;
    }

    const handleExport = () => {

        let root = new SignNode(0, "", "", 1);
        addSign(root, coupon as UserCoupon);

        if (reducedRows.length > 0) {
            exportRows(reducedRows, round);
            return;
        }

        let allRows = getRows(root, []);

        exportRows(allRows, round);
    };

    const handleReduce = () => {

        let root = new SignNode(0, "", "", 1);
        addSign(root, coupon as UserCoupon);

        let allRows = getRows(root, []);
        setReducedRows(allRows);
        let count: Array<number[]> = [];
        for (let gamenr = 0; gamenr < round.fixtures.length; gamenr++) {
            count.push([0, 0, 0])
        }

        for (let index = 0; index < allRows.length; index++) {
            const row = allRows[index].split(',');

            for (let game = 0; game < row.length; game++) {
                const sign = row[game];
                if (sign === "1") {
                    let array = count[game - 1];
                    array[0]++;
                }
                if (sign === "X") {
                    let array = count[game - 1];
                    array[1]++;
                }
                if (sign === "2") {
                    let array = count[game - 1];
                    array[2]++;
                }
            }
        }
        for (let gamenr = 0; gamenr < round.fixtures.length; gamenr++) {
            count[gamenr][0] /= allRows.length;
            count[gamenr][1] /= allRows.length;
            count[gamenr][2] /= allRows.length;
        }
        setReducedPercentages(count);
    };

    function validateCoupon(coupon: UserCoupon): boolean {

        if (!coupon) {
            return false;
        }

        let valid = true;
        let halfCover = 0;
        let wholeCover = 0;
        let nrRows = 1;

        for (let i = 0; i < coupon.couponFixtures.length; i++) {

            let couponFixture = coupon.couponFixtures[i];

            if (!couponFixture["1"] && !couponFixture["X"] && !couponFixture["2"]) {
                valid = false;
            }

            let numSel = ((couponFixture["1"] ? 1 : 0) + (couponFixture["X"] ? 1 : 0) + (couponFixture["2"] ? 1 : 0))
            if (numSel > 0) {
                nrRows *= numSel;
            }
            if (numSel === 2 && !couponFixture.isMathematical) {
                halfCover++;
            }
            if (numSel === 3 && !couponFixture.isMathematical) {
                wholeCover++;
            }
        }
        setCouponInfo(new CouponInfo(nrRows, halfCover, wholeCover));

        return valid;
    }


    return (
        <Grid2 container direction="column" sx={{ alignContent: "center", marginTop: "30px", marginBottom: "20px" }}>

            <Typography>Utdelning</Typography>
            <Box sx={{}}>
                <Slider
                    value={winningsRange}
                    onChange={handleRangeChange}
                    valueLabelDisplay="auto"
                    getAriaValueText={valuetext}
                    min={0}
                    max={round.jackpotUnits}
                />
            </Box>
            <Typography>Tillåtna i block 1</Typography>
            <Box sx={{}}>
                <Slider
                    value={colorRange}
                    onChange={handleColorRangeChange}
                    valueLabelDisplay="auto"
                    min={0}
                    max={5}
                />
            </Box>

            <Button onClick={handleReduce} variant="contained" sx={{ margin: "20px" }}>Reducera</Button>

            <Grid2 container direction="column"
                sx={{ minWidth: "340px", margin: "0px" }}>
                <Typography sx={signText}>{"Rader: " + couponInfo.numRows}</Typography>
                <Typography sx={signText}>{"Rader efter reducering: " + reducedRows.length}</Typography>
                <Typography sx={signText}>{"Helgarderingar: " + couponInfo.numWholeCover}</Typography>
                <Typography sx={signText}>{"Halvgarderingar: " + couponInfo.numHalfCover}</Typography>
                <Typography visibility="hidden" sx={signText}>{update}</Typography>


                <Grid2 container key={"row_"}
                    sx={{ flexDirection: "row", flexWrap: "nowrap" }}
                    alignItems="flex-start">

                    <Box component="div" sx={{ paddingRight: "5px", width: "670px" }} />
                    <Typography sx={teams}>Block 1</Typography>

                </Grid2>

                {round.fixtures.map((fixture, i) => {

                    return (
                        <Grid2 key={"row_header" + i} sx={styleRow}>
                            <Grid2 container key={"row_" + i}
                                sx={{ flexDirection: "row", flexWrap: "nowrap" }}
                                alignItems="stretch">
                                <Grid2 component="div">
                                    <Typography
                                        sx={{ ...teams, ...gameNr } as SxProps<Theme>}>{i + 1}</Typography>
                                </Grid2>
                                <Grid2 component="div" sx={{ flexGrow: 1, paddingRight: "5px", width: "200px" }}>
                                    <Grid2 container>
                                        <Typography sx={teams}>{fixture.homeTeam.name}</Typography>
                                    </Grid2>
                                    <Grid2 container>
                                        <Typography sx={teams}>{fixture.awayTeam.name}</Typography>
                                    </Grid2>
                                </Grid2>

                                <Grid2 container sx={{ flexGrow: 1, paddingRight: "5px", minWidth: "130px" }}>
                                    <Grid2 container direction="column" sx={{ width: "40px" }}>
                                        <Typography sx={teams}>Folket</Typography>
                                        <Typography sx={teams}>System</Typography>
                                    </Grid2>
                                </Grid2>

                                <Grid2 container sx={{ flexGrow: 1, paddingRight: "5px", minWidth: "130px" }}>
                                    <Grid2 container direction="column" sx={{ width: "40px" }}>
                                        <Typography sx={teams}>{(fixture.stakePercentages[1] * 100).toFixed(0) + "%"}</Typography>
                                        {reducedPercentages.length > 0 &&
                                            <Typography sx={teams}>{(reducedPercentages[i][0] * 100).toFixed(0) + "%"}</Typography>
                                        }
                                    </Grid2>
                                    <Grid2 container sx={{ width: "40px" }}>
                                        <Typography sx={teams}>{(fixture.stakePercentages["X"] * 100).toFixed(0) + "%"}</Typography>
                                        {reducedPercentages.length > 0 &&
                                            <Typography sx={teams}>{(reducedPercentages[i][1] * 100).toFixed(0) + "%"}</Typography>
                                        }
                                    </Grid2>
                                    <Grid2 container sx={{ width: "40px" }}>
                                        <Typography sx={teams}>{(fixture.stakePercentages[2] * 100).toFixed(0) + "%"}</Typography>
                                        {reducedPercentages.length > 0 &&
                                            <Typography sx={teams}>{(reducedPercentages[i][2] * 100).toFixed(0) + "%"}</Typography>
                                        }
                                    </Grid2>
                                </Grid2>

                                <Grid2 component="div" sx={{ minHeight: '100%' }}>
                                    <Grid2 container alignItems="center" sx={{
                                        minHeight: '100%',
                                        flexDirection: "row",
                                        flexWrap: "nowrap"
                                    }}>
                                        <ToggleButton name="1"
                                            selected={(coupon as UserCoupon).couponFixtures[i]["1"]}
                                            value="1" sx={button} onChange={() => onButtonChange(i, "1")}>
                                            <Typography sx={signText}>1</Typography>
                                        </ToggleButton>
                                        <ToggleButton name="X"
                                            selected={(coupon as UserCoupon).couponFixtures[i]["X"]}
                                            value="X" sx={button} onChange={() => onButtonChange(i, "X")}>
                                            <Typography sx={signText}>X</Typography>
                                        </ToggleButton>
                                        <ToggleButton name="2"
                                            selected={(coupon as UserCoupon).couponFixtures[i]["2"]}
                                            value="2" sx={button} onChange={() => onButtonChange(i, "2")}>
                                            <Typography sx={signText}>2</Typography>
                                        </ToggleButton>
                                    </Grid2>
                                </Grid2>

                                <Grid2 component="div" sx={{ minHeight: '100%' }}>
                                    <Grid2 container alignItems="center" sx={{
                                        minHeight: '100%',
                                        flexDirection: "row",
                                        flexWrap: "nowrap"
                                    }}>
                                        <ToggleButton name="1"
                                            selected={(colorCoupon as UserCoupon).couponFixtures[i]["1"]}
                                            value="1" sx={buttonBlock} onChange={() => onButtonChangeColor(i, "1")}>
                                            <Typography sx={signText}>1</Typography>
                                        </ToggleButton>
                                        <ToggleButton name="X"
                                            selected={(colorCoupon as UserCoupon).couponFixtures[i]["X"]}
                                            value="X" sx={buttonBlock} onChange={() => onButtonChangeColor(i, "X")}>
                                            <Typography sx={signText}>X</Typography>
                                        </ToggleButton>
                                        <ToggleButton name="2"
                                            selected={(colorCoupon as UserCoupon).couponFixtures[i]["2"]}
                                            value="2" sx={buttonBlock} onChange={() => onButtonChangeColor(i, "2")}>
                                            <Typography sx={signText}>2</Typography>
                                        </ToggleButton>
                                    </Grid2>
                                </Grid2>

                            </Grid2>
                        </Grid2>
                    )
                }

                )}
            </Grid2>

            <Button disabled={!valid} onClick={handleExport} variant="contained" sx={{ margin: "20px" }}>Skapa fil</Button>

        </Grid2>
    );
}
export default FreeCoupon;